import {Grid, Card, CardContent, styled} from '@mui/material';
import Definitions from './definition';
import PropTypes from 'prop-types';

const DefinitionWrapper = styled(Grid)(
  ({theme}) => `
    .definition-block {
      border-bottom: 1px solid #E0E0E0;
      padding: 16px 20px;
      width: 100%;
      color: ${theme.palette.text.primary}
    }
    .definition-block:last-child {
      border-bottom: none;
      padding-bottom: 0px;
    }
  `
);


function DefinitionCard(props) {
  return (
    <Card sx={{ minWidth: 275, textAlign: 'left', mb: 3, boxShadow: '0px 0px 0px 1px #E0E0E0' }}>
      <CardContent sx={{ padding: '0px' }}>
        <DefinitionWrapper>
          <Grid container spacing={0} xs={12} item={true}>
            {props?.definitions.map((data) => (
              <Definitions key={data?.heading} heading={data?.heading} text={data?.text} endLink={data?.endLink} endLinkText={data?.endLinkText} finalText={data?.finalText} list={data?.list} boldDefinitionsList={data?.boldDefinitionsList}></Definitions>
            ))}
          </Grid>
        </DefinitionWrapper>
      </CardContent>
    </Card>
  );
}

DefinitionCard.propTypes = {
  definitions: PropTypes.array
};

export default DefinitionCard;
