import React, { useRef, useState } from 'react';
import { Box, Divider, Grid, List, ListItem, Typography, styled } from '@mui/material';
import PageHeader from 'src/components/PageHeader';
import DefinitionCard from 'src/components/DefinitionCard';
import helpfulGuidanceEntries from './entries';

const GuidanceWrapper = styled(Grid)(
  () => `
    white-space: pre-line;
  `
);

const ListWrapper = styled(List)(
  ({ theme }) => `
    ::-webkit-scrollbar {
      display: none;
    }
    position: sticky;
    overflow-y: scroll;
    top: 100px;
    height: 70vh;
    min-height: 200px;
    max-height: 630px;
    min-width: 300px;
    background: ${theme.palette.background.paper};
    border-radius: 7px;
    border: 1px solid ${theme.palette.action.selected};
    .MuiListItem-root {
      cursor: pointer;
    }
    .MuiListItem-root.link:hover, .MuiListItem-root.active {
      background-color: ${theme.palette.action.hover};
    }

    @media screen and (max-width: 1279px) {
      width: 30%;
    }
  `
);

function HelpfulGuidance() {

  const access = useRef();
  const categoriesRef = useRef();
  const dataIssues = useRef();
  const customerIds = useRef();
  const handleDeletes = useRef();
  const lensScans = useRef();
  const lookbackPeriods = useRef();
  const manageKeys = useRef();
  const manageUsers = useRef();
  const multipleFiles = useRef();
  const recurringScans = useRef();
  const dataUseSummary = useRef();
  const [activeLink, setActiveLink] = useState(null);

  const refsMap = {
    'access': access,
    'categoriesRef': categoriesRef,
    'dataIssues': dataIssues,
    'customerIds': customerIds,
    'handleDeletes': handleDeletes,
    'lensScans': lensScans,
    'lookbackPeriods': lookbackPeriods,
    'manageKeys': manageKeys,
    'manageUsers': manageUsers,
    'multipleFiles': multipleFiles,
    'recurringScans': recurringScans,
    'dataUseSummary': dataUseSummary
  }

  function scrollTo(ref) {
    setActiveLink(ref);
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <PageHeader title="Helpful Guidance" subtitle="Learn more information about LENS to help guide you through your journey." />
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={8}>
          {helpfulGuidanceEntries.map((section, index) => (
            <GuidanceWrapper key={section?.ref}>
              <Box sx={{ mt: '-90px', pt: '90px' }} ref={refsMap[section?.ref]}></Box>
              <Typography variant="h5" sx={{ mb: 3 }}>{section?.title}</Typography>
              {section.entries.map((entry, index) => (
                <>
                  <DefinitionCard key={`${section.title}-${index}`} definitions={entry.definitions}></DefinitionCard>
                  {entry?.note &&
                    <Typography variant="body2"><Typography display="inline" variant="subtitle2" color="text.primary">Note:</Typography> {entry?.note}</Typography>
                  }
                </>
              ))}
              {(helpfulGuidanceEntries?.length !== (index + 1)) && <Divider sx={{ my: 4 }} />}
            </GuidanceWrapper>
          ))}
        </Grid>
        <Grid item xs={4}>
          <ListWrapper>
            <ListItem><Typography variant='subtitle2'>ON THIS PAGE</Typography></ListItem>
            {helpfulGuidanceEntries.map((item) => (
              <ListItem key={item?.ref} className={activeLink === (refsMap[item?.ref]) ? 'link active' : 'link'} sx={{ pt: 1, pb: 1, fontSize: '16px' }} onClick={() => scrollTo(refsMap[item?.ref])}>{item?.title}</ListItem>
            ))}
          </ListWrapper>
        </Grid>
      </Grid>
    </>
  );
}

HelpfulGuidance.propTypes = {};

export default HelpfulGuidance;